let module = false;

export default {};
/*
let context = require.context('@/overrides/client/applications/', true, /\.vue$/);
context.keys().forEach(key => {
  if ('./App.vue' === key) {
    module = context(key);
  }
});

if ( ! module) {
  module = require("@/client/applications/App.vue");
}

export default module.default;
*/

